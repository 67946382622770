* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 20px;
  padding: 0;
}

.app {
  background-color: #ffffff;
}

.boardrow {
  display: flex;
}

.square {
  background: #55aad171;
  border: 2px solid #000000;
  float: left;
  font-size: 16px;
  font-weight: bold;
  line-height: 34px;
  height: 100px;
  width: 100px;
  padding: 0;
  text-align: center;
  position: relative;
}
/*
  commented as it does not work with multi piece select
.square:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a5f3ffa1;
  z-index: 1;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
}
*/

.boardrow:nth-child(even) .square:nth-child(even),
.boardrow:nth-child(odd) .square:nth-child(odd) {
  background-color: #4d6c7dd1;
  /* Dark square color */
}
