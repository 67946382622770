.container {
    display: flex;
}

.controls {
    display: block;
    /* Optional styles for the left element */
}

.debug {
    display: block;
    padding-left: 20px;
    /* Optional styles for the left element */
}

.pieceSelection {
    padding-top: 20px;
    /* Optional styles for the right element */
}

.pieceIcons {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 6 columns */
    grid-template-rows: repeat(6, auto);   /* 2 rows */
    gap: 10px;
    margin-top: 20px;
}

.pieceIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.pieceIcon {
    width: 70px;
    height: 70px;
}