.popover {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding: 2rem;
  text-align: center;
  background-color: #666666;
  color: #ffffff;
  font-size: 3rem;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  background: transparent;
  border: none;
  color: #ffffff;
  cursor: pointer;
  line-height: 1;
  transition: transform 0.2s;
}

.closeButton:hover {
  transform: scale(1.2);
}