.potentialMoveRegular::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #49fca0;
    /* Green highlight for regular moves */
    opacity: 0.5;
    /* Adjust the opacity as needed */
    z-index: 2;
    /* Ensure it's above the square's background and hover effect */
}

.potentialMoveTraverse::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f0fc49;
    /* Green highlight for regular moves */
    opacity: 0.5;
    /* Adjust the opacity as needed */
    z-index: 2;
    /* Ensure it's above the square's background and hover effect */
}

.potentialMoveEntangle::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ae49fc;
    /* Green highlight for regular moves */
    opacity: 0.5;
    /* Adjust the opacity as needed */
    z-index: 2;
    /* Ensure it's above the square's background and hover effect */
}

.potentialMoveFaint::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fca549;
    /* Green highlight for regular moves */
    opacity: 0.5;
    /* Adjust the opacity as needed */
    z-index: 2;
    /* Ensure it's above the square's background and hover effect */
}

.potentialMoveLane::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #868686;
    /* Highlight color */
    opacity: 0.5;
    /* Adjust the opacity as needed */
    z-index: 2;
    /* Ensure it's above the square's background and hover effect */
}

.potentialMoveInfect::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #52441b;
    /* Highlight color */
    opacity: 0.5;
    /* Adjust the opacity as needed */
    z-index: 2;
    /* Ensure it's above the square's background and hover effect */
}

.potentialMoveTrample::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    z-index: 2;
}

.potentialMoveTrampleEmpty::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #49fca0;
    /* Green for non-capturing moves */
    opacity: 0.5;
    z-index: 2;
}

.potentialMoveTrample.empty::after {
    background-color: #49fca0;
    /* Force green for non-capturing moves */
}

.potentialMoveTrample:not(.empty)::after {
    background-color: #f36a58;
    /* Red for capturing moves */
}

.potentialMoveCapture::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f36a58;
    /* Red for capturing moves */
    opacity: 0.5;
    z-index: 2;
}

.potentialMoveHouse::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #49fca0;
    /* Highlight color */
    opacity: 0.5;
    /* Adjust the opacity as needed */
    z-index: 2;
    /* Ensure it's above the square's background and hover effect */
}

.potentialMoveRegular:hover::after,
.potentialMoveLane:hover::after,
.potentialMoveTrampleEmpty:hover::after,
.potentialMoveTraverse:hover::after,
.potentialMoveEntangle:hover::after,
.potentialMoveInfect:hover::after,
.potentialMoveFaint:hover::after
{
    opacity: 0.5 !important;
    background-color: #008cff;
    /* Same color for all */
}

.potentialMoveCapture:hover::after,
.potentialMoveTrample:not(.empty):hover::after {
    opacity: 0.5;
    background-color: #008cff;
    /* Same red for capture and non-empty trample */
}

.potentialMoveHouse:hover::after{
    opacity: 0.5;
    background-color: #008cff;
    /* Same red for capture and non-empty trample */
}

.highlightedSideEffect {
    background-color: rgba(248, 255, 173, 0.5);
    /* Semi-transparent yellow */
}

.virusPiece {
    transform: scale(0.7);
    transform-origin: center center;
    /* Ensure scaling is from the center */
}

/* Base styles for the halves */
.leftHalf,
.rightHalf,
.topHalf,
.bottomHalf {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    /* Change cursor to pointer to indicate interactivity */
}

/* Positioning and default background for each half */
.leftHalf {
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: rgba(174, 73, 252, 0.5);
    /* Purple with opacity */
}

.rightHalf {
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    background-color: rgba(174, 73, 252, 0.5);
}

.topHalf {
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: rgba(174, 73, 252, 0.5);
}

.bottomHalf {
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: rgba(174, 73, 252, 0.5);
}

/* Hover effects for the halves */
.leftHalf:hover,
.rightHalf:hover,
.topHalf:hover,
.bottomHalf:hover {
    background-color: #008cff;
    /* Change color on hover */
    opacity: 0.7;
    /* Adjust opacity as needed */
    border: 2px solid #008cff;
        /* Add a blue border on hover */
}

.pieceItem {
  /* Add your default styles here */
  padding: 10px;
  margin: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}

.pieceItem:hover {
  background-color: #e0e0e0;
  /* Add any other hover styles you want */
  cursor: pointer;
}

.squareicon {
    width: 85%;
    height: 100%;
    object-fit: contain;
}

.selected {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.selected .piece {
    position: relative;
    z-index: 2;
    transform: scale(0.85);
    /* Makes the piece slightly smaller */
    transition: transform 0.3s ease;
}

.selected::before,
.selected::after {
    content: '🍃';
    position: absolute;
    font-size: 20px;
    opacity: 0.6;
    animation: float 3s infinite linear;
}

.selected::before {
    top: 10%;
    left: 10%;
    animation-delay: -1.5s;
}

.selected::after {
    bottom: 10%;
    right: 10%;
}

@keyframes float {

    0%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }

    25% {
        transform: translate(5px, -5px) rotate(5deg);
    }

    50% {
        transform: translate(0, -10px) rotate(0deg);
    }

    75% {
        transform: translate(-5px, -5px) rotate(-5deg);
    }
}

.poisonedPiece {
    /* Convert grayscale image to sepia (brownish), then adjust hue and saturation */
    filter: sepia(4) saturate(12) hue-rotate(60deg);
}

.entangledPiece {
    /* Convert grayscale image to sepia (brownish), then adjust hue and saturation */
    filter: sepia(8) saturate(20) hue-rotate(210deg);
}

.meadowedPiece {
    /* Convert grayscale image to sepia (brownish), then adjust hue and saturation */
    filter: sepia(4) saturate(12) hue-rotate(90deg);
}

.zombifiedPiece {
    /* Convert grayscale image to sepia (brownish), then adjust hue and saturation */
    filter: sepia(5) saturate(20) hue-rotate(160deg);
}

.infectedPiece{
    /* Convert grayscale image to sepia (brownish), then adjust hue and saturation */
        filter: sepia(1) saturate(5) hue-rotate(320deg);
}

.potentialMoveRegular::after,
.potentialMoveCapture::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    z-index: 2;
}

.potentialMoveRegular::after {
    background-color: #49fca0;
}

.potentialMoveCapture::after {
    background-color: #f36a58;
}

.treeContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.treeIcon {
    width: 95%;
    height: 100%;
    object-fit: contain;
}

.overlayIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    /* Bear icon at 80% size */
    height: 85%;
    object-fit: contain;
}

.floatingIcons {
    display: flex;
    gap: 5px;
    position: absolute;
    top: 22%;
    left: 40%;
    transform: translateX(-42%);
    z-index: 10;
}

.floatingIcon {
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.floatingIcon:hover {
    transform: scale(1.1);
    /* Slightly larger on hover */
}

.light {
    color: white;
}

.squareicon {
    width: 85%;
    height: 100%;
    object-fit: contain;
}

.selected {
    position: relative;
    transform: translate(-2px, -2px);
    transition: all 0.3s ease;
    z-index: 1;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    animation: pulse 1.5s infinite;
    /* Added the pulse animation here */
}

.selected::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.2);
    pointer-events: none;
}

.selected img,
.selected svg,
.selected .icon {
    position: relative;
    z-index: 2;
    filter: drop-shadow(-1px -1px 1px rgba(255, 255, 255, 0.5)) drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
}

@keyframes pulse {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}
