/* Modal overlay */
.modalOverlay {
  position: fixed;      /* Stays in place on the screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;       /* Make sure it’s on top */
}

/* Modal container */
.modalContainer {
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem;
  width: 300px;        /* Small modal width */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  position: relative;  /* In case you want to position internal elements absolutely */
}

/* Example heading styles */
.modalContainer h2 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

/* Example button styling */
button {
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}

.pieceIcons {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 6 columns */
    grid-template-rows: repeat(4, auto);   /* 2 rows */
    gap: 10px;
    margin-top: 20px;
}

.pieceIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.pieceIcon {
    width: 70px;
    height: 70px;
}