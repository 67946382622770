.board {
    /* Existing styles... */
    /* display: flex; */
    /* Set display to flex for easy centering */
    justify-content: center;
    /* Horizontally center the content */
    align-items: center;
    /* Vertically center the content */
    /* Other styles as needed... */
    margin: 100 auto;
    display: grid;
    place-items: center;
}
